import React, { useEffect } from "react";
import "./Projects.css";

const ProjectSample = ({ items }) => {

  return (
    <div className="project-sample-container">
      <div className="project-details">
        {items.map((item, idx) => (
          <div className="details-box2" key={`${item.title}-${idx}`}>
            <a
              href={item.projectUrl}
              target="_self"
              rel="noopener noreferrer"
              className="project-link"
            >
              <div className="project-image-container">
                <img
                  draggable="false"
                  src={item.imageUrl}
                  alt={item.title}
                  className="project-image"
                />
              </div>
            </a>
            <a
              href={item.projectUrl}
              rel="noopener noreferrer"
              className="project-link"
            >
              <p className="project-description">{item.title}</p>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectSample;
